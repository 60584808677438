import { Injectable, inject } from '@angular/core';
import { UserSwitchService } from '@app/modules/user-switch';
import { UserFacade } from '@app/store/user';
import { combineLatest, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RoleService {
    private readonly userFacade = inject(UserFacade);
    private readonly userSwitchService = inject(UserSwitchService);

    hasUserSwitchHeader$ = this.userSwitchService.customerId$.pipe(map((userSwitchHeader) => userSwitchHeader !== null));

    isUser$ = this.userFacade.user$.pipe(map((user) => user.roles.includes('ROLE_USER')));
    isSalesperson$ = this.userFacade.user$.pipe(map((user) => user.roles.includes('ROLE_SALESPERSON') || user.roles.includes('ROLE_SALESPERSON_POS')));
    isUserLimited$ = this.userFacade.user$.pipe(map((user) => user.roles.includes('ROLE_USER_LIMITED')));

    asUser$ = combineLatest([this.isUserLimited$, this.isUser$, this.hasUserSwitchHeader$]).pipe(
        map(([isUserLimited, isUser, hasUserSwitchHeader]) => isUserLimited || isUser || hasUserSwitchHeader),
    );
    asSalesperson$ = combineLatest([this.isSalesperson$, this.hasUserSwitchHeader$]).pipe(
        map(([isSalesperson, hasUserSwitchHeader]) => isSalesperson && !hasUserSwitchHeader),
    );
}
